@import './../../_variables'
@import './../../themes.css'

// Path: src\components\Card\Card.module.sass

.mylinks
  &-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    // align-items: center
    margin: 0 auto
    width: 100%
    min-height: 100vh
    //max-width: $galleryWidth
    padding: 0 0
    box-sizing: border-box
    background-color: var(--background-color)
  &-themeswitcher
    position: absolute
    top: 0.5rem
    left: 0.5rem
    z-index: 100
    display: flex
    flex-direction: row
    justify-content: end
    align-items: end
    max-width: $galleryWidth
    padding: 1rem 1rem 0 1rem
    box-sizing: border-box
    background-color: var(--background-color)
    @include media-lg
      padding: 0.1rem 0.1rem 0 0.1rem
    @include media-xxs
      padding: 0.1rem 0.1rem 0 0.1rem
  &-themeButton
    background-color: transparent
    color: var(--heading-color)
    text-align: center
    padding: 0.5rem 1rem
    border: none
    cursor: pointer
    font-weight: $bold
    font-size: $highlight-text-font-size * 0.75
    transition: color 0.3s, transform 0.3s
    &:hover
      color: rgba(0, 0, 0, 0.6)
      transform: scale(1.1)
    &:active
      color: rgba(0, 0, 0, 0.8)
      transform: scale(1.05)
  &-langswitcher
    position: absolute
    top: 0.5rem
    right: 0.5rem
    z-index: 100
    display: flex
    flex-direction: row
    justify-content: end
    align-items: end
    max-width: $galleryWidth
    padding: 1rem 1rem 0 1rem
    box-sizing: border-box
    background-color: var(--background-color)
    @include media-lg
      padding: 0.1rem 0.1rem 0 0.1rem
    @include media-xxs
      padding: 0.1rem 0.1rem 0 0.1rem
  &-languageButton
    background-color: transparent
    color: var(--heading-color)
    text-align: center
    padding: 0.5rem 1rem
    border: none
    cursor: pointer
    font-weight: $bold
    font-size: $highlight-text-font-size * 0.75
    transition: color 0.3s, transform 0.3s
    &:hover
      color: rgba(0, 0, 0, 0.6)
      transform: scale(1.1)
    &:active
      color: rgba(0, 0, 0, 0.8)
      transform: scale(1.05)
  &-headerWrapper
    margin: 4rem auto 2rem auto
    // border: 1px solid red
    position: relative
    z-index: 1
    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      transform: translate(-20px, -20px) // Add translate property
      width: 100%
      max-width: $galleryWidth
      height: 100%
      background-color: var(--section-color)
      border: 1px solid var(--section-color)
      border-radius: 16px
      z-index: -1 // Add z-index property
      @include media-xxs
        transform: translate(-10px, -10px) // Add translate property
        display: none
    @include media-sm
      margin: 3rem 1rem 2rem 2rem
    @include media-xxs
      margin: 3rem 0.5rem 1rem 0.5rem
  &-header
    background-color: var(--background-color)
    display: grid
    grid-template-columns: minmax(100px, auto) minmax(100px, 375px)
    grid-column-gap: 4rem
    grid-auto-rows: minmax(100px, auto)
    justify-content: space-between
    align-items: center
    max-width: $galleryWidth
    box-sizing: border-box
    border: 1px solid var(--accent-color)
    border-radius: 16px
    margin-bottom: 0rem
    // padding: 2rem
    position: relative
    z-index: 1
    padding-right: 2rem
    @include media-sm
      grid-column-gap: 2rem
      grid-auto-rows: minmax(50px, auto)
    @include media-sms
      grid-template-columns: 1fr
      grid-template-rows: auto auto auto
      grid-row-gap: 1rem
      padding-right: 0
    @include media-xxs
      grid-row-gap: 0.5rem
  &-headerName
    grid-column: 2/3
    grid-row: 1/2
    display: flex
    flex-direction: column
    width: 100%
    align-items: end
    @include media-sms
      grid-column: 1/2
      grid-row: 1/2
      align-items: center
  &-brandname
    @include brandname
    margin-bottom: 0
    padding-right: 1rem
    @include media-sm
      margin-top: 0.5rem
    @include media-xxs
      padding-right: 0
  &-subtitle
    @include font-paragraph-text
    color: var(--heading-color)
    margin: 0
    margin-bottom: 1rem
    text-align: center
    padding-right: 2rem
    @include media-xxs
      padding-right: 0
      right: 0
      position: relative
      top: -10px
      right: -40px
      margin-bottom: 0
  &-logo
    width: 100%
    max-width: 300px
    grid-column: 1/2
    grid-row: 1/3
    border-radius: 16px
    @include media-sms
      grid-column: 1/2
      grid-row: 2/3
      margin: 0 auto
      margin-bottom: 1rem
    @include media-xxs
      margin: 0 auto
      margin-bottom: 0
  &-text
    @include font-paragraph-text
    color: var(--text-color)
    margin: 0
    margin-bottom: 1rem
    text-align: justify
    max-width: $galleryWidth
    @include media-sms
      margin-left: 2rem
      margin-right: 2rem
    @include media-xxs
      margin-left: 1rem
      margin-right: 1rem
      font-size: $paragraph-text-font-size*0.9
  &-hr
    width: 30%
    height: 0px
    max-width: 300px
    margin: 0 auto
    margin-bottom: 1rem
    background-color: var(--accent-color)
    border: 1px solid var(--accent-color)
    border-radius: 1px
  &-title
    @include font-section-title
    color: var(--text-color)
    margin: 1rem 0
    text-align: center
    @include media-xxs
      font-size: $section-title-font-size*0.8
  &-articles
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem
    align-items: center
    justify-items: center
    width: 100%
    max-width: $galleryWidth
    margin: 0 auto
    margin-bottom: 1rem
    padding: 0
    list-style: none
    @include media-xs
      grid-template-columns: 1fr
  &-soon
    display: grid
    position: relative
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem
    align-items: center
    justify-items: center
    width: 100%
    max-width: $galleryWidth
    margin: 0 auto
    margin-bottom: 1rem
    padding: 0
    list-style: none
    & a
      filter: grayscale(80%) opacity(20%) blur(1.75px) // remove this line, you, imbicile, to make the links visible
      pointer-events: none // remove this line, you, imbicile, to make the links clickable
    & h3
      color: var(--heading-color)
    &::before
      content: 'Something beautiful is coming'
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 10
      color: var(--heading-color)
      font-weight: $bold
      text-align: center
      // text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3)
      @include brandname
  &-contact
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    max-width: 375px
    margin: 0 auto
    margin-bottom: 1rem
    padding: 0
    list-style: none
  &-whatsapp
    @include font-paragraph-text
    font-weight: $bold
    background-color: #25D366
    border: 2px solid #25D366
    color: var(--background-color)
    text-decoration: none
    border-radius: 16px
    width: 100%
    padding: 0.65rem 1rem
    display: grid
    grid-template-columns: 28px 1fr
    grid-template-rows: 1fr
    grid-row-gap: 0px
    grid-column-gap: 10px
    align-items: center
    justify-items: center
    transition: transform 0.05s ease-in-out
    margin: 1rem 0
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9)
  &-svg
    width: 36px
    height: 36px
    fill: var(--background-color) !important
    grid-column: 1 / 2
    grid-row: 1 / 2
    z-index: 10
      // @include font-face($roboto, $bold)
  &-telegram
    @include font-paragraph-text
    font-weight: $bold
    background-color: #0088CC
    color: var(--background-color)
    text-decoration: none
    border: 2px solid #0088CC
    border-radius: 16px
    width: 100%
    padding: 0.65rem 1rem
    display: grid
    grid-template-columns: 28px 1fr
    grid-template-rows: 1fr
    grid-row-gap: 0px
    grid-column-gap: 10px
    align-items: center
    justify-items: center
    transition: transform 0.05s ease-in-out
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9)
h1
  color: var(--heading-color)
