/* Colors */
:root {
  --heading-color: #40403F;
  --text-color: #646464;
  --section-color: #FDFDFD;
  --background-color: #F6F2EE;
  --accent-color: #E6CBBD;
}

/* Dark theme */
[data-theme='dark'] {
  --heading-color: #ffffff;
  --text-color: #cccccc;
  --section-color: #333333;
  --background-color: #222222;
  --accent-color: #E6CBBD;
  /* --accent-color: #444444; */
}
