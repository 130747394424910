// Fonts
@import url('https://fonts.googleapis.com/css2?family=Gelasio:wght@400;600&display=swap')
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap')

// // Colors
// $heading-color: #40403F
// // $text-color: #8F8F8F
// $text-color: #646464
// $section-color: #FDFDFD
// $background-color: #F6F2EE
// $accent-color: #E6CBBD

// Font Sizes
$display-title-font-size: 4rem
$subtitle-font-size: 2.5rem
$lead-text-font-size: 1.125rem
$section-title-font-size: 1.75rem
$section-category-font-size: 1rem
$card-title-font-size: 1.125rem
$paragraph-text-font-size: 1rem
$highlight-text-font-size: 1rem

// Font Weights
$extra-bold: 800
$bold: 700
$medium: 500
$regular: 400

// Mixins
@mixin font-display-title
  font-family: 'Gelasio', serif
  font-size: $display-title-font-size
  font-weight: $extra-bold

$galleryWidth: 1000px

$breakpoint-xxs: 476px
$breakpoint-xs: 576px
$breakpoint-sms: 726px
$breakpoint-sm: 768px
$breakpoint-md: 992px
$breakpoint-lg: 1200px

@mixin font-subtitle
  font-family: 'Gelasio', serif
  font-size: $subtitle-font-size
  font-weight: $extra-bold

@mixin font-lead-text
  font-family: 'Montserrat', sans-serif
  font-size: $lead-text-font-size
  font-weight: $medium

@mixin font-section-title
  font-family: 'Montserrat', sans-serif
  font-size: $section-title-font-size
  font-weight: $bold

@mixin font-section-category
  font-family: 'Montserrat', sans-serif
  font-size: $section-category-font-size
  font-weight: $medium

@mixin font-card-title
  font-family: 'Montserrat', sans-serif
  font-size: $card-title-font-size
  font-weight: $bold

@mixin font-paragraph-text
  font-family: 'Montserrat', sans-serif
  font-size: $paragraph-text-font-size
  font-weight: $regular

@mixin font-highlight-text
  font-family: 'Montserrat', sans-serif
  font-size: $highlight-text-font-size
  font-weight: $bold

@mixin brandname
  font-family: 'Clicker Script', cursive
  font-size: $display-title-font-size

@mixin media-xxs
  @media (max-width: $breakpoint-xxs - 1)
    @content

@mixin media-xs
  @media (max-width: $breakpoint-xs - 1)
    @content

@mixin media-sms
  @media (max-width: $breakpoint-sms - 1)
    @content

@mixin media-sm
  @media (max-width: $breakpoint-sm - 1)
    @content

@mixin media-md
  @media (max-width: $breakpoint-md - 1)
    @content

@mixin media-lg
  @media (max-width: $breakpoint-lg - 1)
    @content

@mixin media-max($width)
  @media (max-width: $width - 1)
    @content
