@import './../../_variables'

.card
  display: flex
  position: relative
  flex-direction: column
  align-items: center
  background-color: var(--background-color)
  //padding: 1rem
  border-radius: 16px
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)
  margin: 1rem

.image
  width: 100%
  border-radius: 16px

.heading
  @include font-card-title
  color: var(--accent-color)
  font-size: $section-title-font-size
  margin-top: 1rem
  text-align: center
  position: absolute
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9)
  font-weight: $bold

.link
  // display: block
  position: absolute
  bottom: 0
  background-color: rgba(var(--accent-color), 0.65)
  color: white
  text-align: center
  padding: 0.5rem 0
  width: 100%
  border-radius: 16px
  text-decoration: none
  font-weight: $bold
  font-size: $highlight-text-font-size
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9)
  // opacity: 0.7
